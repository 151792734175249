<template>
  <div>
    <div class="registerBox">
      <div class="title">新用户注册</div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="100px"
        autocomplete="off"
      >
        <div class="section-title">登录信息</div>
        <el-form-item label="用户名" prop="UserName">
          <el-input v-model="form.UserName"></el-input>
        </el-form-item>
        <div class="tipmsg">
          {{ msg }}
        </div>
        <el-form-item label="密码" prop="PassWord">
          <el-input
            type="password"
            v-model="form.PassWord"
            maxlength="18"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            type="password"
            v-model="form.confirmPassword"
            maxlength="18"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <div class="section-title">个人信息</div>
        <el-form-item label="姓名" prop="Name">
          <el-input v-model="form.Name"></el-input>
        </el-form-item>
        <el-form-item class="custom-item">
          <div class="tip">该手机号将用于找回密码，请务必填写准确！</div>
        </el-form-item>
        <el-form-item label="手机号" prop="PhoneNumber">
          <el-input v-model="form.PhoneNumber" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="CaptchCode">
          <el-input
            v-model="form.CaptchCode"
            placeholder="输入验证码"
            maxlength="6"
          >
            <CaptchaButton
              slot="append"
              :beforeClick="beforeClick"
              @get-code="getCode"
            ></CaptchaButton>
          </el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="Mail">
          <el-input v-model="form.Mail"></el-input>
        </el-form-item>
        <el-form-item label="工作单位" prop="Company">
          <el-input v-model="form.Company"></el-input>
        </el-form-item>
        <el-form-item label="科室" prop="Department">
          <el-input v-model="form.Department"></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="Duty">
          <el-input v-model="form.Duty"></el-input>
        </el-form-item>
        <el-form-item label="工作证照片" required>
          <el-upload
            class="upload-demo"
            :action="actionUrl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            list-type="picture"
          >
            <div v-if="fileList.length == 0">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png格式</div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认注册</el-button>
          <el-button @click="oncancel">返回登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Register, CreateAndSendCaptcha } from "@/api";
import util from "@/static/util.js";
import CaptchaButton from "@/components/CaptchaButton/CaptchaButton";
import Vue from "vue";

export default {
  name: "Register",
  data() {
    // 手机号码格式规则
    var checkPhone = (rule, value, callback) => {
      if (!/^1(3|4|5|7|8|9)\d{9}$/.test(value)) {
        callback(new Error("手机号码格式不对"));
      } else {
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      const patt = util.EMAIL_REG;
      if (!patt.test(value)) {
        callback(new Error("邮箱格式不对"));
      } else {
        callback();
      }
    };
    var checkCaptchCode = (rule, value, callback) => {
      if (!util.VCODE_REG.test(value)) {
        callback(new Error("必须是6位数字"));
      } else {
        callback();
      }
    };
    var checkPassword = (rule, value, callback) => {
      const reg = /^(?![^a-zA-Z]+$)(?!\D+$)/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("密码包含字母和数字"));
      } else if (value.length < 6) {
        callback(new Error("密码至少6位"));
      } else {
        callback();
      }
    };
    var checkPassword2 = (rule, value, callback) => {
      if (value !== this.form.PassWord) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };

    return {
      rules: {
        PhoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        CaptchCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { validator: checkCaptchCode, trigger: "blur" },
        ],
        Mail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" },
        ],
        PassWord: [
          { required: true, message: "输入登录密码", trigger: "blur" },
          { validator: checkPassword, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "再次输入密码", trigger: "blur" },
          { validator: checkPassword2, trigger: "blur" },
        ],
        UserName: [{ required: true, message: "请输入用户名" }],
        Name: [{ required: true, message: "请输入姓名" }],
        Department: [{ required: true, message: "请输入部门" }],
        Company: [{ required: true, message: "请输入工作单位" }],
        Duty: [{ required: true, message: "请输入职务" }],
      },
      form: {
        WorkCode: "",
        CaptchCode: "",
        PassWord: "",
        UserName: "",
        Name: "",
        PhoneNumber: "",
        Mail: "",
        Department: "",
        Company: "",
        Duty: "",
        confirmPassword: "",
        FileIds: [],
      },
      msg: "密码必须包括6-18位字母、数字",
      fileList: [],
      actionUrl: Vue.axios.defaults.baseURL + "/Upload/UpLoadFile",
    };
  },
  methods: {
    beforeClick() {
      if (this.form.PhoneNumber == "") {
        this.$message.warning("手机号不能为空");
        return false;
      }
      return true;
    },
    getCode(WorkCode) {
      this.form.WorkCode = WorkCode;
      this.CreateAndSendCaptchaAsync();
    },
    handleRemove(file) {
      const i = this.fileList.findIndex((f) => f.uid == file.uid);
      this.fileList.splice(i, 1);
      const j = this.form.FileIds.findIndex(
        (ID) => ID == file.response.ID
      );
      this.form.FileIds.splice(j, 1);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleSuccess(response, file) {
      this.form.FileIds.push(response.ID);
      const obj = {
        name: file.name,
        url: file.url,
        fileID: response.ID,
        uid: file.uid,
      };
      this.fileList.push(obj);
    },
    onSubmit() {
      if(this.form.FileIds.length == 0) {
        this.$message.warning('请上传工作照')
        return
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.registerAsync();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    oncancel() {
      this.$router.go(-1);
    },
    async registerAsync() {
      const r = await Register({
        ...this.form,
      });
      if (r.code === 1) {
        this.$message.success("注册成功");
        this.$router.go(-1);
      } else {
        const msg = r.error.Message;
        this.$message.error(msg);
      }
    },
    async CreateAndSendCaptchaAsync() {
      const r = await CreateAndSendCaptcha({
        PhoneNumber: this.form.PhoneNumber,
        WorkCode: this.form.WorkCode,
      });
      if (r.code === 1) {
        this.$message.success("验证码已发送");
      } else {
        const msg = r.error.Message;
        this.$message.error(msg);
      }
    },
  },
  created() {},
  components: {
    CaptchaButton,
  },
};
</script>

<style scoped>
.registerBox {
  width: 400px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 30px;
  margin: 0 auto;
  background-color: #ffffff;
  margin-top: 100px;
}
.title {
  line-height: 28px;
  /* margin-bottom: 30px; */
  padding: 4px;
  border-bottom: 1px solid #ccc;
  border-bottom-color: #cce2c1;
  color: #69aa46;
  font-size: 19px;
}
.section-title {
  color: #3a87ad;
  padding: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.tip {
  color: #409eff;
  font-size: 12px;
}
.custom-item {
  margin: 0;
}
.tipmsg {
  color: #409eff;
  font-size: 12px;
  margin-left: 100px;
  padding: 10px 0;
}
</style>