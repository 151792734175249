var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.btnClick
    }
  }, [_vm._v(_vm._s(_vm.codeText))]);
};
var staticRenderFns = [];
export { render, staticRenderFns };